import React, { Fragment } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typekit from "../components/Typekit";
import { Helmet } from "react-helmet";
import "../styl/main.styl";

const NotFoundPage = () => (
  <Fragment>
    <Helmet>
      <link rel="shortcut icon" href="/images/ampersand.png" />
    </Helmet>
    <Typekit id="gqk8csn" />
    <main>
      <Header className="header-short" />
      <div className="container center four-oh-four">
        <figure>
          <img src="/images/illustration-yeti.gif" alt={"Prancing yeti"} />
        </figure>
        <h2>Sorry, there’s nothing here. <br />Just you and me.</h2>
      </div>
    </main>
    <Footer />
  </Fragment>
);

export default NotFoundPage;
